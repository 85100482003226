import {useEffect, useState} from "react";
import {AXIOS} from "../axiosWithCredentials";
import {API_URL} from "../App";
import querystring from "querystring";
import {useHistory} from "react-router-dom";

export default function useLogIn() {

    const history = useHistory()

    const getUserFromStorage = () => {
        return JSON.parse(localStorage.getItem('user'))
    }

    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('user') !== null)
    const [user, setUser] = useState(isLoggedIn ?
        getUserFromStorage() :
        {}
    )

    const saveUser = (user) => {
        localStorage.setItem('user', JSON.stringify(user))
        setIsLoggedIn(true)
    }

    const logIn = (values) => {
        AXIOS.post(
            API_URL + '/login',
            querystring.stringify({...values}),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(
                () => getUserDetails()
                    .then(user => saveUser(user))
            )
            .catch(e => console.log(e))
    }

    const getUserDetails = () => {
        return AXIOS.get(API_URL + '/users/me')
            .then(response => {
                const name = response.data.username
                const role = response.data.authorities.length === 1 ? response.data.authorities[0] : null
                return {name, role}
            })
            .catch(e => console.log(e))
    }

    const logOut = () => {
        if (isLoggedIn) {
            AXIOS.get(API_URL + '/logout')
                .catch(e => console.log(e))
            console.log('useLogin.js: Logging out')
            localStorage.removeItem('user');
            setIsLoggedIn(false)
            setUser({})
        }
    }

    const logOutOnUnauthorized = e => {
        if (e.response.status !== 403) {
            console.log('useLogin.js#logOutOnUnauthorized: ' + e.response.status)
            return
        }
        logOut()
        history.push('/login')
        if (e.response) {
            console.log('useLogin.js#logOutOnUnauthorized: ' + e.response.data.status + ' ' + e.response.data.error + ': ' + e.response.data.message)
        } else {
            console.log('useLogin.js#logOutOnUnauthorized: ' + e.message)
        }
    }

    useEffect(() => {
        setIsLoggedIn(localStorage.getItem('user') !== null)
        if (isLoggedIn)
            setUser(getUserFromStorage())
    }, [isLoggedIn])

    return {isLoggedIn, user, logIn, logOut, logOutOnFail: logOutOnUnauthorized}
}