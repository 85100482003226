import React from 'react'
import Page from "../component/Page";
import useDevices from "./useDevices";
import EzTable from "../component/EzTable";
import EditDevicePopup from "./EditDevicePopup";
import dic from "../dictionary";

export default function DevicesPage() {

    const {devices, editDevice} = useDevices()

    return (
        <Page>
            <EzTable data={devices}
                     headers={['ID', dic.devices.i1, dic.devices.i2, dic.devices.i3, dic.devices.i4, dic.devices.i5, dic.devices.i6, dic.devices.i7, dic.devices.i8, dic.devices.i9, dic.devices.i10, dic.devices.i11, dic.devices.i12, dic.devices.a1]}
                     fields={['id', 'i1', 'i2', 'i3', 'i4', 'i5', 'i6', 'i7', 'i8', 'i9', 'i10', 'i11', 'i12', 'a1']}
                     editFunction={editDevice}
                     EditComponent={EditDevicePopup}
            />
        </Page>
    )

}