import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import useForm from "../hook/useForm";
import dic from "../dictionary";

export default function AddUserForm({addUserFunction: addUser}) {

    const {values, handleChange, handleSubmit} = useForm(addUser, ['username', 'password', 'role'], {role: "ROLE_USER"})

    return (
        <Form onSubmit={handleSubmit}>
            <Row xs={1} md={3}>
                <Form.Group as={Col}>
                    <Form.Label>{dic.users.username}</Form.Label>
                    <Form.Control type="text" placeholder={dic.users.username} name="username"
                                  value={values.username} onChange={handleChange} required/>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{dic.users.password}</Form.Label>
                    <Form.Control type="password" placeholder={dic.users.password} name="password"
                                  value={values.password} onChange={handleChange} required/>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{dic.users.role}</Form.Label>
                    <Form.Control as="select" name="role"
                                  value={values.role} onChange={handleChange} required>
                        <option>ROLE_USER</option>
                        <option>ROLE_ADMIN</option>
                    </Form.Control>
                </Form.Group>
            </Row>
            <Row>
                <Col md={{span: 6, offset: 3}} xs={{span: 12, offset: 0}}>
                    <Button type="submit" className="btn-block">
                        {dic.forms.add}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}