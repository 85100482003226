import React from "react"
import NavBar from "./NavBar"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Page(props) {

    return (
        <div>
            <NavBar/>
            <Container className="mt-5">
                <Row>
                    <Col sm="12" md="12">
                        {props.children}
                    </Col>
                </Row>
            </Container>
        </div>
    )

}
