import {API_URL} from "../App";
import {AXIOS} from "../axiosWithCredentials";
import useLogIn from "../hook/useLogIn";
import {useEffect, useState} from "react";

export default function useUsers() {

    const URL = `${API_URL}/users`
    const {logOutOnFail} = useLogIn()
    const [users, setUsers] = useState([])

    useEffect(() => {
        listUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listUsers = () => {
        AXIOS.get(URL, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.length) {
                    let users = [...response.data]
                        .filter(user => user.authorities.length !== 0)
                        .map(user => {
                            return {username: user.username, authority: user.authorities[0]}
                        })
                    setUsers(users)

                }
            })
            .catch(logOutOnFail)
    }

    const addUser = (values) => {
        console.log(values)
        AXIOS.post(URL, {...values}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(listUsers)
            .catch(e => {
                if (e.response.status === 409)
                    alert(e.response.data.message)
                logOutOnFail(e)
            })
    }

    return {users, addUser}
}