import {API_URL} from "../App";
import {AXIOS} from "../axiosWithCredentials";
import useLogIn from "../hook/useLogIn";
import {useEffect, useState} from "react";

export default function useStations() {

    const URL = `${API_URL}/stations`
    const {logOutOnFail} = useLogIn()
    const [stations, setStations] = useState([])

    useEffect(() => {
        listStations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listStations = () => {
        AXIOS.get(URL, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.length) {
                    let stations = [...response.data]
                        .map(station => {
                            return {
                                id: station.id,
                                name: station.name,
                                deviceId: station.deviceId,
                                surfaceArea: station.surfaceArea
                            }
                        })
                    setStations(stations)
                }
            })
            .catch(logOutOnFail)
    }

    const addStation = (values) => {
        console.log(values)
        AXIOS.post(URL, {...values}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(listStations)
            .catch(logOutOnFail)
    }

    const editStation = (values) => {
        let editStationDto = {};
        for (const key in values) {
            if (values[key] !== '')
                editStationDto[key] = values[key]
        }
        AXIOS.put(URL + '/' + values.id, {...editStationDto}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(listStations)
            .catch(e => {
                if (e.response.status === 409) {
                    alert(e.response.data.message)
                    return
                }
                logOutOnFail(e)
            })
    }

    return {stations, addStation, editStation}

}
