import React from 'react';
import './App.css';
import {Route, Switch} from "react-router-dom";
import LoginPage from "./page/LoginPage";
import PrivateRoute from "./component/PrivateRoute";
import Error404 from "./page/Error404";
import UsersPage from "./user/UsersPage";
import LogOutPage from "./page/LogOutPage";
import DevicesPage from "./device/DevicesPage";
import StationsPage from "./station/StationsPage";

const {REACT_APP_BASE_URL: API_URL} = process.env;

export {API_URL}

export default function App() {

    return (
        <Switch>
            <Route path="/login" component={LoginPage}/>
            <PrivateRoute roleNeeded={"ROLE_ADMIN"} path="/users" component={UsersPage}/>
            <PrivateRoute roleNeeded={"ROLE_USER"} path="/devices" component={DevicesPage}/>
            <PrivateRoute roleNeeded={"ROLE_USER"} path="/stations" component={StationsPage}/>
            <PrivateRoute roleNeeded={"ROLE_USER"} path="/logout" component={LogOutPage}/>
            <PrivateRoute roleNeeded={"ROLE_USER"} path="/" component={LoginPage}/>
            <Route component={Error404}/>
        </Switch>
    );

}
