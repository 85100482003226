import React from "react";
import Popup from "reactjs-popup";
import Button from "react-bootstrap/Button";
import EditStationForm from "./EditStationForm";
import dic from "../dictionary";

export default function EditStationPopup({values, editFunction}) {

    return (
        <Popup trigger={<Button>Edytuj</Button>} modal>
            {close => (
                <div className="edit-popup">
                    <div className="header">{dic.stations.editingStation + ' ' + values.id}</div>
                    <div className="content">
                        <EditStationForm editStationFunction={editFunction} stationValues={values}
                                         onSubmit={close}/>
                    </div>
                </div>
            )}
        </Popup>
    )

}
