import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import useForm from "../hook/useForm";
import dic from "../dictionary";

export default function AddStationForm({addStationFunction: addStation}) {

    const {values, handleChange, handleSubmit} = useForm(addStation, ['name', 'surfaceArea'])

    return (
        <Form onSubmit={handleSubmit}>
            <Row xs={1} md={2}>
                <Form.Group as={Col}>
                    <Form.Label>{dic.stations.name}</Form.Label>
                    <Form.Control type="text" placeholder={dic.stations.name} name="name"
                                  value={values.name} onChange={handleChange} required/>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{dic.stations.surfaceArea}</Form.Label>
                    <Form.Control type="number" placeholder={dic.stations.surfaceArea} name="surfaceArea"
                                  value={values.surfaceArea} onChange={handleChange} required/>
                </Form.Group>
            </Row>
            <Row>
                <Col md={{span: 6, offset: 3}} xs={{span: 12, offset: 0}}>
                    <Button type="submit" className="btn-block">
                        {dic.forms.add}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}