import React from 'react'
import {Redirect} from "react-router-dom";
import useLogIn from "../hook/useLogIn";

export default function LogOutPage() {

    const {isLoggedIn, logOut} = useLogIn()

    const performLogout = () => {
        if (isLoggedIn) {
            console.log('LogOutPage.js: logging out')
            logOut()
        }
    }

    performLogout()

    return (
        <Redirect to='/login'/>
    )

}