const dic = {
    navbar: {
        name: 'System Ciągłego Nadzoru',
        users: 'Użytkownicy',
        devices: 'Urządzenia',
        stations: 'Stacje',
        login: 'Zaloguj',
        logout: 'Wyloguj'
    },
    users: {
        username: 'Nazwa użytkownika',
        password: 'Hasło',
        role: 'Rola'
    },
    stations: {
        id: 'Id',
        name: 'Nazwa',
        surfaceArea: 'Powierzchnia',
        device: 'Urządzenie',
        editingStation: 'Edycja stacji'
    },
    devices: {
        editingDevice: 'Edycja urządzenia',
        i1: 'I1',
        i2: 'I2',
        i3: 'I3',
        i4: 'I4',
        i5: 'I5',
        i6: 'I6',
        i7: 'I7',
        i8: 'I8',
        i9: 'I9',
        i10: 'I10',
        i11: 'I11',
        i12: 'I12',
        a1: 'Analog1',
        level: 'Sonda',
        Floater1: 'Pł 1',
        Floater2: 'Pł 2',
        Floater3: 'Pł 3',
        FloaterMin: 'Pł min',
        FloaterMax: 'Pł max',
        Pump1State: 'P1 stan',
        Pump1SafetyBreaker: 'P1 bezp',
        Pump2State: 'P2 stan',
        Pump2SafetyBreaker: 'P2 bezp',
        DoorSensor: 'Drzwi',
        MainPowerSupply: 'Zasilanie'
    },
    forms: {
        add: 'Dodaj',
        save: 'Zapisz',
        login: 'Zaloguj'
    },
    error404: {
        msg: 'Nic tu nie ma.'
    }
}

export default dic
