import React from 'react'
import Page from "../component/Page";
import useStations from "./useStations";
import AddStationForm from "./AddStationForm";
import EzTable from "../component/EzTable";
import EditStationPopup from "./EditStationPopup";
import dic from "../dictionary";

export default function StationsPage() {

    const {stations, addStation, editStation} = useStations()

    return (
        <Page>
            <EzTable data={stations}
                     headers={[dic.stations.id, dic.stations.name, dic.stations.surfaceArea, dic.stations.device]}
                     fields={['id', 'name', 'surfaceArea', 'deviceId']}
                     editFunction={editStation}
                     EditComponent={EditStationPopup}
            />
            <AddStationForm addStationFunction={addStation}/>
        </Page>
    )

}