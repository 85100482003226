import {NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import React from "react";

export default function NavLinkBootstrap(props) {

    return (
        <Nav.Link as={NavLink} exact to={props.to} className={`${props.className ? props.className : ''}`}
                  id={props.id ? props.id : ''}>
            {props.children}
        </Nav.Link>
    )

}
