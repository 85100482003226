import Table from "react-bootstrap/Table";
import React from "react";

export default function EzTable({data, headers, fields, editFunction, EditComponent}) {

    let row = 0
    let col = 0

    const Headers = () => {
        return (
            <thead>
            <tr>
                {editFunction ? <th/> : <></>}
                {
                    headers ? headers.map(
                        header => {
                            return (
                                <th key={header}>{header}</th>
                            )
                        }
                        ) :
                        <th>No headers provided</th>
                }
            </tr>
            </thead>
        )
    }

    const Cell = (field) => {
        return (
            <td key={row + '_' + col++}>{field}</td>
        )
    }

    const Row = (entry) => {
        return (
            <tr key={row++}>
                {
                    EditComponent ?
                        <td><EditComponent values={entry} editFunction={editFunction}/></td> :
                        <></>
                }
                {
                    fields ?
                        fields.map(
                            field => Cell(entry[field])
                        ) :
                        <td>No fields provided</td>
                }
            </tr>
        )
    }

    const Body = () => {
        return (
            <tbody>
            {
                data ?
                    data.map(
                        entry => Row(entry)
                    ) :
                    <></>
            }
            </tbody>
        )
    }

    return (
        <div className={"table-responsive"}>
            <Table striped hover bordered>
                <Headers/>
                <Body/>
            </Table>
        </div>
    )

}
