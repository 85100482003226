import React from 'react'
import useForm from "../hook/useForm";
import {Redirect} from "react-router-dom";
import Page from "../component/Page";
import useLogIn from "../hook/useLogIn";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import dic from "../dictionary";

export default function LoginPage(props) {

    const {isLoggedIn, logIn} = useLogIn()

    const {values, handleChange, handleSubmit} = useForm(logIn, ['username', 'password'])

    if (isLoggedIn) {
        console.log(props.location.state)
        const {from} = props.location.state || {from: {pathname: '/stations'}}
        return <Redirect to={from.pathname}/>
    }

    return (
        <Page>
            <Form onSubmit={handleSubmit}>
                <Row xs={1} md={2}>
                    <Form.Group as={Col}>
                        <Form.Label>{dic.users.username}</Form.Label>
                        <Form.Control type="text" placeholder={dic.users.username} name="username"
                                      value={values.username} onChange={handleChange} required/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>{dic.users.password}</Form.Label>
                        <Form.Control type="password" placeholder={dic.users.password} name="password"
                                      value={values.password} onChange={handleChange} required/>
                    </Form.Group>
                </Row>
                <Row>
                    <Col md={{span: 6, offset: 3}} xs={{span: 12, offset: 0}}>
                        <Button type="submit" className="btn-block">
                            {dic.forms.login}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Page>
    )
}