import React from 'react'
import Container from "react-bootstrap/Container";
import {NavLink} from "react-router-dom";
import dic from "../dictionary";

export default function Error404() {
    return (
        <NavLink to={'/stations'}>
            <Container className="d-flex align-items-center justify-content-center text-center h-100 text-secondary">
                <div>
                    <h1 className={'display-1 font-weight-bold'}>404</h1>
                    <h2>{dic.error404.msg}</h2>
                </div>
            </Container>
        </NavLink>
    )
}