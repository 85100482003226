import React from 'react'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavLinkBootstrap from "./NavLinkBootstrap";
import useLogIn from "../hook/useLogIn";
import dic from "../dictionary";

export default function NavBar() {

    const {isLoggedIn, user} = useLogIn();

    return (
        <Navbar expand="lg" className="navbar-dark bg-primary">
            <Navbar.Brand href="/stations">
                {dic.navbar.name}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {isLoggedIn ?
                        <>
                            {
                                user.role === "ROLE_ADMIN" ?
                                    <NavLinkBootstrap to="/users">{dic.navbar.users}</NavLinkBootstrap>
                                    :
                                    <></>
                            }
                            <NavLinkBootstrap to="/devices">{dic.navbar.devices}</NavLinkBootstrap>
                            <NavLinkBootstrap to="/stations">{dic.navbar.stations}</NavLinkBootstrap>
                        </>
                        :
                        <></>
                    }
                </Nav>
                <Nav>
                    {isLoggedIn ?
                        <NavLinkBootstrap to="/logout">{dic.navbar.logout}</NavLinkBootstrap>
                        :
                        <NavLinkBootstrap to="/login">{dic.navbar.login}</NavLinkBootstrap>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )

}
