import {API_URL} from "../App";
import {AXIOS} from "../axiosWithCredentials";
import useLogIn from "../hook/useLogIn";
import {useEffect, useState} from "react";
import {translateEnum} from "./DeviceEnumTranslation";

export default function useDevices(getUnassigned) {

    const URL = `${API_URL}/devices`
    const {logOutOnFail} = useLogIn()
    const [devices, setDevices] = useState([])
    const [unassignedDevices, setUnassignedDevices] = useState([])

    useEffect(() => {
        if (getUnassigned)
            listUnassignedDevices();
        else
            listDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listDevices = () => {
        AXIOS.get(URL, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.length) {
                    let devices = [...response.data]
                        .map(device => {
                            return {
                                id: device.id,
                                i1: translateEnum(device.i1),
                                i2: translateEnum(device.i2),
                                i3: translateEnum(device.i3),
                                i4: translateEnum(device.i4),
                                i5: translateEnum(device.i5),
                                i6: translateEnum(device.i6),
                                i7: translateEnum(device.i7),
                                i8: translateEnum(device.i8),
                                i9: translateEnum(device.i9),
                                i10: translateEnum(device.i10),
                                i11: translateEnum(device.i11),
                                i12: translateEnum(device.i12),
                                a1: translateEnum(device.a1),
                            }
                        })
                    setDevices(devices)
                }
            })
            .catch(logOutOnFail)
    }

    const editDevice = (values) => {
        let editDeviceDto = {};
        for (const key in values) {
            if (values[key] !== '')
                editDeviceDto[key] = values[key]
        }
        AXIOS.put(URL + '/' + values.id, {...editDeviceDto}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(listDevices)
            .catch(e => {
                if (e.response.status === 422)
                    alert(e.response.data.message)
                logOutOnFail(e)
            })
    }

    const listUnassignedDevices = () => {
        AXIOS.get(URL + '/unassigned', {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.length) {
                    let devices = [...response.data]
                        .map(device => device.id)
                    setUnassignedDevices(devices)
                }
            })
            .catch(logOutOnFail)
    }

    return {devices, editDevice, unassignedDevices}
}