import React from 'react'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import dic from "../dictionary";

export default function BinaryInputGroup({label, selectName, value, handleChange}) {

    return (
        <Form.Row lg={6} xs={{span: 12, offset: 0}}>
            <Form.Label column>{label}</Form.Label>
            <Col>
                <Form.Control as="select" name={selectName}
                              value={value} onChange={handleChange}>
                    <option/>
                    <option value='FloaterMin'>{dic.devices.FloaterMin}</option>
                    <option value='Floater1'>{dic.devices.Floater1}</option>
                    <option value='Floater2'>{dic.devices.Floater2}</option>
                    <option value='Floater3'>{dic.devices.Floater3}</option>
                    <option value='FloaterMax'>{dic.devices.FloaterMax}</option>
                    <option value='Pump1State'>{dic.devices.Pump1State}</option>
                    <option value='Pump1SafetyBreaker'>{dic.devices.Pump1SafetyBreaker}</option>
                    <option value='Pump2State'>{dic.devices.Pump2State}</option>
                    <option value='Pump2SafetyBreaker'>{dic.devices.Pump2SafetyBreaker}</option>
                    <option value='DoorSensor'>{dic.devices.DoorSensor}</option>
                    <option value='MainPowerSupply'>{dic.devices.MainPowerSupply}</option>
                </Form.Control>
            </Col>
        </Form.Row>
    )

}
