import React from 'react'
import Page from "../component/Page";
import useUsers from "./useUsers";
import AddUserForm from "./AddUserForm";
import EzTable from "../component/EzTable";
import dic from "../dictionary";

export default function UsersPage() {

    const {users, addUser} = useUsers()

    return (
        <Page>
            <EzTable data={users} headers={[dic.users.username, dic.users.role]} fields={['username', 'authority']}/>
            <AddUserForm addUserFunction={addUser}/>
        </Page>
    )

}
