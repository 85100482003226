import dic from "../dictionary";

export const translateEnum = (enumValue) => {
    switch (enumValue) {
        case 'FloaterMin':
            return dic.devices.FloaterMin
        case 'Floater1':
            return dic.devices.Floater1
        case 'Floater2':
            return dic.devices.Floater2
        case 'Floater3':
            return dic.devices.Floater3
        case 'FloaterMax':
            return dic.devices.FloaterMax
        case 'Pump1State':
            return dic.devices.Pump1State
        case 'Pump1SafetyBreaker':
            return dic.devices.Pump1SafetyBreaker
        case 'Pump2State':
            return dic.devices.Pump2State
        case 'Pump2SafetyBreaker':
            return dic.devices.Pump2SafetyBreaker
        case 'DoorSensor':
            return dic.devices.DoorSensor
        case 'MainPowerSupply':
            return dic.devices.MainPowerSupply
        case 'Level':
            return dic.devices.level
        default:
            return ''
    }
}

export const revertToEnum = (selectValue) => {
    switch (selectValue) {
        case dic.devices.FloaterMin:
            return 'FloaterMin'
        case dic.devices.Floater1:
            return 'Floater1'
        case dic.devices.Floater2:
            return 'Floater2'
        case dic.devices.Floater3:
            return 'Floater3'
        case dic.devices.FloaterMax:
            return 'FloaterMax'
        case dic.devices.Pump1State:
            return 'Pump1State'
        case dic.devices.Pump1SafetyBreaker:
            return 'Pump1SafetyBreaker'
        case dic.devices.Pump2State:
            return 'Pump2State'
        case dic.devices.Pump2SafetyBreaker:
            return 'Pump2SafetyBreaker'
        case dic.devices.DoorSensor:
            return 'DoorSensor'
        case dic.devices.MainPowerSupply:
            return 'MainPowerSupply'
        case dic.devices.level:
            return 'Level'
        default:
            return ''
    }
}
