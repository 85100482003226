import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import useForm from "../hook/useForm";
import useDevices from "../device/useDevices";
import dic from "../dictionary";

export default function EditStationForm({editStationFunction: editStation, stationValues, onSubmit}) {

    let editedStationFields = {}
    for (const key in stationValues) {
        editedStationFields[key] = stationValues[key]
    }

    const {unassignedDevices} = useDevices(true)

    const {values, handleChange, handleSubmit} = useForm(
        editStation,
        ['name', 'deviceId', 'surfaceArea'],
        editedStationFields
    )

    const submitAndClose = (event) => {
        handleSubmit(event)
        onSubmit()
    }

    return (
        <Form onSubmit={submitAndClose}>
            <Form.Row lg={6} xs={{span: 12, offset: 0}}>
                <Form.Label as={Col}>{dic.stations.name}</Form.Label>
                <Col>
                    <Form.Control type="text" placeholder={dic.stations.name} name="name"
                                  value={values.name} onChange={handleChange} required/>
                </Col>
            </Form.Row>
            <Form.Row lg={6} xs={{span: 12, offset: 0}}>
                <Form.Label as={Col}>{dic.stations.surfaceArea}</Form.Label>
                <Col>
                    <Form.Control type="number" placeholder={dic.stations.surfaceArea} name="surfaceArea"
                                  value={values.surfaceArea} onChange={handleChange} required/>
                </Col>
            </Form.Row>
            <Form.Row lg={6} xs={{span: 12, offset: 0}}>
                <Form.Label column>{dic.stations.device}</Form.Label>
                <Col>
                    <Form.Control as="select" name="deviceId"
                                  value={values.deviceId ? values.deviceId : ''} onChange={handleChange}
                    >
                        <option/>
                        {
                            values.deviceId ?
                                <option value={values.deviceId}>{values.deviceId}</option> :
                                <></>
                        }
                        {
                            unassignedDevices ? unassignedDevices.map(
                                device => {
                                    return (
                                        <option value={device} key={device}>{device}</option>
                                    )
                                }) :
                                <></>
                        }
                    </Form.Control>
                </Col>
            </Form.Row>
            <input type='hidden' name="id" value={values.id}/>
            <Row>
                <Col md={{span: 6, offset: 3}} xs={{span: 12, offset: 0}}>
                    <Button type="submit" className="btn-block">
                        {dic.forms.save}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}