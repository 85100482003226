import {useState} from 'react'

export default function useForm(callback, fields, defaults) {

    const initValues = {}
    for (const key of fields)
        initValues[key] = ''

    for (const key in defaults)
        initValues[key] = defaults[key]

    const [values, setValues] = useState(initValues)

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        callback(values)
    }

    const handleChange = (event) => {
        event.persist()
        setValues(values => ({...values, [event.target.name]: event.target.value}))
    }

    return {values, handleChange, handleSubmit}
}