import React from "react";
import {Redirect, Route} from "react-router-dom";
import useLogIn from "../hook/useLogIn";


export default function PrivateRoute({component: Component, roleNeeded, ...props}) {

    const {isLoggedIn, user} = useLogIn()

    if (isLoggedIn && !(user.role === 'ROLE_ADMIN' || user.role === roleNeeded)) {
        return <Redirect to="/stations"/>
    }

    return (
        <Route
            {...props}
            render={innerProps =>
                isLoggedIn ?
                    <Component {...innerProps} />
                    :
                    <Redirect to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}/>
            }
        />
    )
}
