import React from "react";
import Popup from "reactjs-popup";
import Button from "react-bootstrap/Button";
import EditDeviceForm from "./EditDeviceForm";
import dic from "../dictionary";

export default function EditDevicePopup({values, editFunction}) {

    return (
        <Popup trigger={<Button>Edytuj</Button>} modal>
            {close => (
                <div className="edit-popup">
                    <div className="header">{dic.devices.editingDevice + ' ' + values.id}</div>
                    <div className="content">
                        <EditDeviceForm editDeviceFunction={editFunction} deviceValues={values}
                                        onSubmit={close}/>
                    </div>
                </div>
            )}
        </Popup>
    )

}
