import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import useForm from "../hook/useForm";
import BinaryInputGroup from "./BinaryInputGroup";
import {revertToEnum} from "./DeviceEnumTranslation";
import dic from "../dictionary";

export default function EditDeviceForm({editDeviceFunction: editDevice, deviceValues, onSubmit}) {

    let editedDeviceFields = {}
    for (const key in deviceValues) {
        if (key === 'id') {
            editedDeviceFields[key] = deviceValues[key]
            continue
        }
        editedDeviceFields[key] = revertToEnum(deviceValues[key])
    }

    const {values, handleChange, handleSubmit} = useForm(
        editDevice,
        ['id', 'i1', 'i2', 'i3', 'i4', 'i5', 'i6', 'i7', 'i8', 'i9', 'i10', 'i11', 'i12', 'a1'],
        editedDeviceFields
    )

    const submitAndClose = (event) => {
        handleSubmit(event)
        onSubmit()
    }

    return (
        <Form onSubmit={submitAndClose}>
            <BinaryInputGroup label={dic.devices.i1} selectName='i1' value={values.i1} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i2} selectName='i2' value={values.i2} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i3} selectName='i3' value={values.i3} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i4} selectName='i4' value={values.i4} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i5} selectName='i5' value={values.i5} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i6} selectName='i6' value={values.i6} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i7} selectName='i7' value={values.i7} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i8} selectName='i8' value={values.i8} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i9} selectName='i9' value={values.i9} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i10} selectName='i10' value={values.i10} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i11} selectName='i11' value={values.i11} handleChange={handleChange}/>
            <BinaryInputGroup label={dic.devices.i12} selectName='i12' value={values.i12} handleChange={handleChange}/>
            <Form.Row lg={6} xs={{span: 12, offset: 0}}>
                <Col>
                    <Form.Label>{dic.devices.a1}</Form.Label>
                </Col>
                <Col>
                    <Form.Control as="select" name="a1"
                                  value={values.a1} onChange={handleChange}>
                        <option/>
                        <option value='Level'>{dic.devices.level}</option>
                    </Form.Control>
                </Col>
            </Form.Row>
            <input type='hidden' name="id" value={values.id}/>
            <Row>
                <Col md={{span: 6, offset: 3}} xs={{span: 12, offset: 0}}>
                    <Button type="submit" className="btn-block">
                        {dic.forms.save}
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}